//
// Notes
//


// Variables
$timeline-3-media-size: 50px;
$timeline-3-border-width: 4px;
$border-color: $gray-400;

.timeline.timeline-3 {
    .timeline-items {
        margin: 0;
        padding: 0;

        .timeline-item {
            margin-left: $timeline-3-media-size/2;
            border-left: $timeline-3-border-width solid #FFF; //$border-color;
            padding: 0 0 20px $timeline-3-media-size;
            //padding: 0 0 20px 40px; //--> the 40px here moves the content box left and right relative to the media circle
            position:relative;

            .timeline-media {
                position: absolute;
                top: 0;
                left: -(($timeline-3-media-size + $timeline-3-border-width)/2);
                //border: 2px solid $border-color;
                border-radius: 100%;
                width: $timeline-3-media-size;
                height: $timeline-3-media-size;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $white;
                line-height: 0;

                i {
                    font-size: 1.4rem;
                }

                .svg-icon {
                    @include svg-icon-size(24px);
                }

                img {
                    max-width: $timeline-3-media-size - $timeline-3-border-width;
                    max-height: $timeline-3-media-size - $timeline-3-border-width;
                    border-radius: 100%;
                }
            }

            .timeline-content {
                //@include border-radius($border-radius);
                border-radius: 0;
                position:relative;
                //background-color: $gray-100;
                background-color: #FFFFFF;
                padding: 0.75rem 1.5rem;

                &:before {
					position: absolute;
					content: '';
					width: 0;
					height: 0;
					top: 12px; //--> this 12px moves the arrow head up and down on the content box, trying to center it to the media circle should be 12.5 original was 10px
					left: -(22px); //--> 19px moves the arrow head left and right relative to the content box
					border-right: solid 11px $white; //$gray-400;
					border-bottom: solid 11px transparent; //--> these values make thr arrow head smaller they were originally 10/18/18/18
					border-left: solid 11px transparent;
					border-top: solid 11px transparent;
				}
            }

            &:last-child {
                border-left-color: transparent;
                padding-bottom: 0;
            }
        }
    }
}


// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
    .timeline.timeline-3 {

    }
}
