//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//


// Theme colors
// Override primary color variants

// Black color
$black:    											#000000 !default;

// White color
$white:    											#ffffff !default;

// Gray colors
$gray-100: 											#F3F6F9 !default;
$gray-200: 											#EBEDF3 !default;
$gray-300: 											#E4E6EF !default;
$gray-400: 											#D1D3E0 !default;
$gray-500: 											#B5B5C3 !default;
$gray-600: 											#7E8299 !default;
$gray-700: 											#5E6278 !default;
$gray-800: 											#3F4254 !default;
$gray-900: 											#181C32 !default;

// Dark colors
$dark:          									$gray-900 !default;
$dark-75:          									$gray-800 !default; // Custom variable
$dark-65:          									$gray-700 !default; // Custom variable
$dark-50:          									$gray-600 !default; // Custom variable
$dark-25:          									$gray-400 !default; // Custom variable

$primary:       									  #663399; // Bootstrap variable
$primary-hover-light:              rgba(102, 51, 153, 0.15);  // Custom variable
//$primary-hover-light: #bf9fdf;
$primary-hover:    				   darken($primary, 10%); // Custom variable
$primary-light:                     rgba(102, 51, 153, 0.15);
//$primary-light: #e6d9f2;
//$primary-light:                   #EEE5FF; // The old info-light color
$primary-inverse:  									#FFFFFF; // Custom variable

$success:                           #1bc5bd;
$success-hover:    					darken($success, 10%); // Custom variable
$success-hover-light:    			lighten($success, 40%); // Custom variable
$success-light:    					lighten($success, 40%); // Custom variable
$success-inverse:  					#FFFFFF; // Custom variable

$font-size-base:              		1rem; // Changed to recommended 16px for web apps
$font-size-lg:               	 	$font-size-base * 1.08;   // 14.04px
$font-size-sm:                		$font-size-base * .925;   // 12.025px
$font-size-xs:                		$font-size-base * .8;     // 10.4px

$warning:       					#FFA800 !default; // Bootstrap variable
$warning-light:                     #FFF4DE !default;
$brown-1: rgb(73, 69, 70);
$black: #000000;

$info: navy;





.app-logo {
  opacity: .3;
  filter: alpha(opacity=30);
}
.carousel-status {
  display: none;
}
.carousel .control-dots {
  margin-bottom: 0;
}
.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  //box-shadow: 1px 1px 2px rgba(0,0,0,0.9);
  box-shadow: 0;
  background: #fff;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;

  outline: 0px solid transparent;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  background: #FFF;

  outline: 0px solid transparent;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  display: none;
}
.carousel .thumbs-wrapper {
  display: none;
}

.circle-radius {
  border-radius: 50%;
}

.grow-h {
  flex: 1;
}

.darker-success {
  color: #107671;
}
.dark-success {
  color: #159D97;
}
.light-success {
  color: #48D0CA;
}
.lighter-success {
  color: #76DCD7;
}
.bg-darker-success {
  background-color: #107671;
}
.bg-dark-success {
  background-color: #159D97;
}
.bg-light-success {
  background-color: #48D0CA;
}
.bg-lighter-success {
  background-color: #76DCD7;
}

.darker-danger {
  color: #932E39;
}
.dark-danger {
  color: #C43E4C;
}
.light-danger {
  color: #F7717F;
}
.lighter-danger {
  color: #F9949F;
}
.bg-darker-danger {
  background-color: #932E39;
}
.bg-dark-danger {
  background-color: #C43E4C;
}
.bg-light-danger {
  background-color: #F7717F;
}
.bg-lighter-danger {
  background-color: #F9949F;
}


.fa-spin {
  animation: fa-spin 1s infinite linear;
}
@-webkit-keyframes fa-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}
@keyframes fa-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}


//html,body {
  //box-sizing: border-box;
//}
//*, *:before, *:after { box-sizing: inherit; }

//html {
  /* body will set it's height based on its parent, which is html */
  //height: 100%;
  //width: 100%;
//}
//body {
  /* min-height is needed for pages that might scroll, ie they may contain _more_ than 100% of viewport height */
  //min-height: 100%;

  /* needed to prevent unwanted scroll-bars */
  //margin: 0;
  //padding: 0;  
//}

/* Full height centered app container */
#app-container {

  /* Set block height using the viewport height unit, and force it to be full height, even if the containing content does not require it */
  min-height: 100vh;

  /* center this block for larger viewports */
  margin: 0 auto;

  /*Use max-width for better browser handling of small windows
  https://www.w3schools.com/css/css_max-width.asp
  */
  max-width: 31.25em;
  width: 100%;
 
  /* Use min-width to prevent your design from being scrunched up in tiny viewports (and instead be replaced with horizontal scroll bars) 
  */
  min-width: 18.75em;
   
  /* Add padding around your content - this is just an arbitrary number */
  padding: 1em;


  /* Add sufficient top padding so the fixed header doesn't cover up main content by defailt.  This value should be a calculated value (eg using calc(), sass, etc):
  padding for this container + height of app header; Here, that is 1em + 4em */ 
  padding-top: 5em;

  /* just used to so we can tell the content block apart from the body block */
  background-color: white;

  position: relative;

}

#app-container-preview {

  /* Set block height using the viewport height unit, and force it to be full height, even if the containing content does not require it */
  //min-height: 100vh;

  /* center this block for larger viewports */
  margin: 0 auto;

  /*Use max-width for better browser handling of small windows
  https://www.w3schools.com/css/css_max-width.asp
  */
  max-width: 31.25em;
  width: 100%;
 
  /* Use min-width to prevent your design from being scrunched up in tiny viewports (and instead be replaced with horizontal scroll bars) 
  */
  min-width: 18.75em;
   
  /* Add padding around your content - this is just an arbitrary number */
  padding: 1em;


  /* Add sufficient top padding so the fixed header doesn't cover up main content by defailt.  This value should be a calculated value (eg using calc(), sass, etc):
  padding for this container + height of app header; Here, that is 1em + 4em */ 
  padding-top: 5em;

  /* just used to so we can tell the content block apart from the body block */
  background-color: white;

  position: relative;

}

#main-content {
  min-width: 100%;
  max-width: 100%;

  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

#main-content-preview {
  min-width: 100%;
  max-width: 100%;

  margin: 0 auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


#app-header {
  /* fix the header to the top of the viewport. */
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  z-index:10;

  /* this should match the min-width in app-container. Recommend setting using a common variable (eg with Sass) */
  min-width: 300px;

  /* internal layout, centered vertically - flex layout is great for this */
  display: flex;
  flex-flow: row nowrap;
  align-items:center;
}

#app-header-preview {
  /* fix the header to the top of the viewport. */
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  z-index:10;

  /* this should match the min-width in app-container. Recommend setting using a common variable (eg with Sass) */
  min-width: 300px;

  /* internal layout, centered vertically - flex layout is great for this */
  display: flex;
  flex-flow: row nowrap;
  align-items:center;
}

.app-header-content {
  /* set the h1 block as the main flex block - it will fill all unused space in the block */
  flex: 1;

  /* center content inside the block */
  text-align: center;
}

#app-footer {
  /* fix the header to the top of the viewport. */
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  z-index:10;

  /* this should match the min-width in app-container. Recommend setting using a common variable (eg with Sass) */
  min-width: 300px;

  /* internal layout, centered vertically - flex layout is great for this */
  display: flex;
  flex-flow: row nowrap;
  align-items:center;
}

#app-footer-preview {
  /* fix the header to the top of the viewport. */
  position: absolute;
  bottom: 0;
  left:0;
  right: 0;
  z-index:10;

  /* this should match the min-width in app-container. Recommend setting using a common variable (eg with Sass) */
  min-width: 300px;

  /* internal layout, centered vertically - flex layout is great for this */
  display: flex;
  flex-flow: row nowrap;
  align-items:center;
}

.app-footer-content {
  /* set the h1 block as the main flex block - it will fill all unused space in the block */
  flex: 1;

  /* center content inside the block */
  text-align: center;
}

.app-next-button {
  //flex: 1;
  //text-align: center;
  position: absolute;
  bottom: -200px;
}


.login-aside1{
  position: relative; 
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-aside1 ::before {    
  content: "";
  background-image: url('/media/bg/bg-3.jpg');
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.05;
}

.spinner-1 {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner-1 > div {
  width: 18px;
  height: 18px;
  background-color: $warning;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-1 .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-1 .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.hover-icon-container:hover .hover-icon {
  opacity: 1;
}
.hover-icon {
  opacity: 0;
  transition: .3s ease-in-out;
}


.pac-container {
    z-index: 10000 !important;
    border-radius: 0 !important;
    margin-top: 4px;
    box-shadow: 0px 0px 0px 0px $gray-100 !important;
    border-left: 1px solid $gray-300 !important;
    border-top: 0px solid $gray-300!important;
    border-bottom: 0px solid $gray-300 !important;
    padding: 0px !important;
}
.pac-icon {
  color: $primary !important;
}
.pac-item {
  cursor: pointer;
  padding: 8px !important;
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
  border-left: 2px solid transparent;
  border-radius: 0px 20px 20px 0px;
  font-size: 0.8em !important;
  font-family: Poppins, Helvetica, "sans-serif" !important;   
}
.pac-item-query {
  font-size: 1.1em !important;
  font-family: Poppins, Helvetica, "sans-serif" !important;  
}
.pac-item:hover {
  background-color: $gray-100;
  border-left: 2px solid $primary; 
}
.pac-item-selected,
.pac-item-selected:hover {
  background-color: $gray-100;
  border-left: 2px solid $primary;
}
.pac-matched {
  color: $primary;
  font-size: 1.1em !important;
}
.hdpi.pac-logo:after {
    display: none;
}
.pac-logo:after {
    display: none;
}


.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.hover-border {
  border-left: 3px solid #fff;
}
.hover-border :hover {
  border-left: 3px solid $primary;
}

.overlay.overlay-block {
    cursor: default;
}

/* Smartphones ----------- */
@media only screen and (max-width: 760px) {
  #screen-size-detector { 
    display: none; 
    height: 0px;
    width: 0px;
  }
}

.hideMe {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

.ql-toolbar.ql-snow {
  border-top: 0px solid #663399;
  border-bottom: 1px solid $gray-300;
  border-left: 0px solid $gray-300;
  border-right: 0px solid $gray-300;
  background-color: white;
}
.ql-container.ql-snow {
  border-bottom: 0px solid $gray-300;
  border-left: 0px solid $gray-300;
  border-right: 0px solid $gray-300;
  border-radius: 0px 0px 0px 0px;
  //background-color: $gray-100;
  height: 5em;
  font-size: 1em;
}
.ql-formats > button { 
  &:hover, &:focus, &:active {
    .ql-stroke {
      stroke: $primary;
    }
  }
}

.ql-snow.ql-toolbar button:hover, 
.ql-snow.ql-toolbar button:focus,  
.ql-snow.ql-toolbar button.ql-active,  
.ql-snow.ql-toolbar .ql-picker-label:hover, 
.ql-snow.ql-toolbar .ql-picker-label.ql-active, 
.ql-snow.ql-toolbar .ql-picker-item:hover, 
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: $primary;
}

 
.ql-snow .ql-toolbar button:hover .ql-stroke, 
.ql-snow.ql-toolbar button:focus .ql-stroke,  
.ql-snow.ql-toolbar button.ql-active .ql-stroke, 
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, 
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, 
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, 
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, 
.ql-snow.ql-toolbar button:hover .ql-stroke-miter, 
.ql-snow.ql-toolbar button:focus .ql-stroke-miter, 
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, 
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, 
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, 
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, 
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: $primary;
}

.ql-editor {
  font-family: Poppins, Helvetica, "sans-serif";  
}

form { // giving forms some room to breathe in the right hand drawer
  padding-left: 5px;
  padding-right: 5px;
}

body .tagify {
  --tag-bg: transparent;
  --tag-text-color: #3F4254;
  --tag-text-color--edit: #663399;
  //--tag-hover: #ccb3e673;
  --tag-hover: transparent;
  --tag-remove-btn-color: #663399;
  --tag-remove-bg: #E4E6EF;
  --tag-remove-btn-bg: transparent;
  --tag-remove-btn-bg--hover: #F64E60;
  //--tag-pad: 0.8em 0.8em;
  //--tag-inset-shadow-size: 2px;
  --tag-invalid-color: #FFF;
  --tag-invalid-bg:  #F64E60;  

}

.tagify__input {
  color: $dark;
  font-size: $font-size-base;
}
 
.tagify__tag {
  z-index: 0;
  padding: 0;
}

.tagify__tag > div {
    font-size: 0.9em;
    font-weight: 500;
  }

.tagify__tag__removeBtn {
  color: #663399;
  border-radius: 5px 5px 5px 5px;
  font-weight: 400;
  //margin-left: 2px;
}

.tagify__tag__removeBtn:hover {
  color: #fff;
  background: #F64E60;
  //background: var(--tag-remove-btn-bg--hover);
}

.tagify__tag__removeBtn::after {
    //content: "\00D7";
}

.smallTagInput {
  min-height: 3em;
}

.mediumTagInput {
  min-height: 6em;
}

.largeTagInput {
  min-height: 9em; 
}


.customSuggestionsList > div{
  max-height: 300px;
  border: 0px solid #E4E6EF;
  border-radius: 0px;
  overflow: auto;
  background-color: #fff;
  outline: 0px solid transparent;

}

.tagify__dropdown__item--active {
  background-color: $gray-100;
}

.messageDiv {
  min-height: 6em;
  line-height: 1.5em;
  font-family: Poppins, Helvetica, "sans-serif";
}

.smsPanelDiv {
  min-height: 4em;
  line-height: 1.5em;
  font-family: Poppins, Helvetica, "sans-serif";
}

.emailPanelDiv {
  min-height: 9em;
  line-height: 1.5em;
  font-family: Poppins, Helvetica, "sans-serif";
}


[contenteditable] {
  outline: 0px solid transparent;
}

.dark-custom-bg {
  background-color: #10163a;
}

.c_overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($black, 0.1);
  //transition: all 0.3s ease;
  opacity: 1;
  z-index: 4; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.MD-shadow {
  box-shadow: 0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12);
}

.react-datepicker__input-container {
  > input, input :active, :hover, :focus {
    background-color: #ccb3e63b;

    border-top: 1px solid $primary;
    border-bottom: 1px solid $gray-300;
    border-right: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    border-radius: 0 0 15px 0;
    padding: 10px;    
  } 
}

.react-datepicker-ignore-onclickoutside {
  outline: none;
  
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: $primary;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-bottom-color: $primary;
}

.react-datepicker {
  font-family: Poppins, Helvetica, "sans-serif";  
}
.react-datepicker__day--selected {
  background: $primary !important;
  outline: none;
  font-weight: bold;
}
.react-datepicker__header {
  background-color: $primary-light !important;
  border-bottom: 0px;  
  border-radius: 0px;
}
.react-datepicker__current-month {
  font-size: 1.15em;
  font-weight: normal;
}
.react-datepicker-time__input {
  cursor: pointer;
  border: 1px solid $primary-light;
  padding: 0px 2px 0px 0px;
  border-radius: 20px;
  > input :active, :hover, :focus {
    border: 0px;
    outline: 0px;
  }
  > input {
    border: 0px;
  }
}

.limit-chars {
  white-space: nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

@media only screen and (min-width: 600px) {
  .max-chars-lg-8 {
    max-width: 8ch;
  }
  .max-chars-lg-9 {
    max-width: 9ch;
  }
  .max-chars-lg-10 {
    max-width: 10ch;
  }
  .max-chars-lg-11 {
    max-width: 11ch;
  }
  .max-chars-lg-12 {
    max-width: 12ch;
  }
  .max-chars-lg-13 {
    max-width: 13ch;
  }
  .max-chars-lg-14 {
    max-width: 14ch;
  }
  .max-chars-lg-15 {
    max-width: 15ch;
  }
  .max-chars-lg-16 {
    max-width: 16ch;
  }
  .max-chars-lg-17 {
    max-width: 17ch;
  }
  .max-chars-lg-18 {
    max-width: 18ch;
  }
  .max-chars-lg-19 {
    max-width: 19ch;
  }
  .max-chars-lg-20 {
    max-width: 20ch;
  }
  .max-chars-lg-30 {
    max-width: 30ch;
  }
  .max-chars-lg-35 {
    max-width: 35ch;
  }
  .max-chars-lg-45 {
    max-width: 45ch;
  }
  .max-chars-lg-55 {
    max-width: 55ch;
  }
  .max-chars-lg-65 {
    max-width: 65ch;
  }
  .max-chars-lg-75 {
    max-width: 75ch;
  }
  .max-chars-lg-100 {
    max-width: 100ch;
  }
  .max-chars-lg-125 {
    max-width: 125ch;
  }
  .max-chars-lg-150 {
    max-width: 150ch;
  }
  .max-chars-lg-175 {
    max-width: 175ch;
  }  
}
@media only screen and (max-width: 600px) {
  .max-chars-25 {
    max-width: 25ch;
  }
  .max-chars-8 {
    max-width: 8ch;
  }
  .max-chars-9 {
    max-width: 9ch;
  }
  .max-chars-10 {
    max-width: 10ch;
  }
  .max-chars-11 {
    max-width: 11ch;
  }
  .max-chars-12 {
    max-width: 12ch;
  }
  .max-chars-13 {
    max-width: 13ch;
  }
  .max-chars-14 {
    max-width: 14ch;
  }
  .max-chars-15 {
    max-width: 15ch;
  }
  .max-chars-16 {
    max-width: 16ch;
  }
  .max-chars-17 {
    max-width: 17ch;
  }
  .max-chars-18 {
    max-width: 18ch;
  }
  .max-chars-19 {
    max-width: 19ch;
  }
  .max-chars-20 {
    max-width: 20ch;
  }
  .max-chars-30 {
    max-width: 30ch;
  }
  .max-chars-35 {
    max-width: 35ch;
  }
  .max-chars-45 {
    max-width: 45ch;
  }
  .max-chars-55 {
    max-width: 55ch;
  }
  .max-chars-65 {
    max-width: 65ch;
  }
  .max-chars-75 {
    max-width: 75ch;
  }
    
}




.button-title { // For the titles under icons inside of buttons, moving the text closer to the icon vertically
  position:relative;
  top: -2px;
}


.card-title-vert {
  padding-top: 2px;
  padding-bottom: 2px;

}

.card-vert-dist {
  padding-top: 2px;
  padding-bottom: 2px;
}

//Box shadow varieties

.shadow-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.shadow-1:hover {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.shadow-2 {
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.shadow-3 {
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.shadow-4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.shadow-5 {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

.c-box-shadow {
    box-shadow: -5px 5px 10px -5px rgba(0,0,0,.3), 5px 5px 10px -5px rgba(0,0,0,.1);
    z-index: 2;
}

.c-box-shadow-up {
    box-shadow: -5px -5px 10px -5px rgba(0,0,0,.1), 5px -5px 10px -5px rgba(0,0,0,.1);
    z-index: 2;
}

@mixin animation ($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-timing-function: linear;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; 
  -moz-animation-timing-function: linear;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; 
  animation-timing-function: linear;
}

@mixin keyframes ($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(slide-in) {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(slide-out) {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@include keyframes(slide-right) {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(slide-left) {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(zoom-in) {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(rotation-lr) {
  from  {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0);
  }
}

@include keyframes(rotation-rl) {
  from  {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}

@include keyframes(slide-right-solid) {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(slide-left-solid) {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}



@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity:1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity:0;
  }
}

.grandparent {
  position:relative;
}

.parent {
  position:absolute;
  bottom:0px;
}

.child {
  position:absolute;
  left:0px;
  bottom:0px;
  z-index: 1;
  //animation: fadeIn 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.3s, slide-left);
}

.footer-btn {
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.3s, slide-left);
}


.apps-menu {
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.3s, slide-left);
}

.apps-send {
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.3s, slide-right);
}

.big-button-menu {
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.2s, slide-right);
}

.convo-list-anim {
  //transition: transform 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.1s, slide-right-solid);
}

.convo-anim {
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.3s, slide-left-solid);
}

.apps-rotate {
  transition: all 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
  @include animation(0, 0.2s, rotation-lr);
}


.c_fadeIn {
  animation: fadeIn 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.c_fadeOut {
  animation: fadeOut 2s ease;
}

.messages-spacer {
  background-color: transparent;
  transition: all 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
  
}


.speech-bubble {
  position:relative;
}

.right-bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid rgba(102, 51, 153, 0.25); // this special setting matches the border color in light-primary which is a weird darker shade of light-primary as compared to background-color
  //border-left: 5px solid rgb(230, 217, 242) !important;
  
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(102, 51, 153, 0.25);
  //border-top: 5px solid rgb(230, 217, 242) !important;

  border-bottom: 5px solid transparent;
  right: -11px;
  //top: 5px;
  top: -1px;
}

.right-bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 4px solid #e8e0ef !important; // light // this is the hex form of the light-primary color
  //border-left: 4px solid $primary-light !important; // light // this is the hex form of the light-primary color  
  border-right: 4px solid transparent;
  border-top: 4px solid #e8e0ef !important;  // light
  //border-top: 4px solid $primary-light !important;  // light
  border-bottom: 4px solid transparent;
  right: -8px;
  //top: 6px;
  top: 0px;
}


.left-bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid #E4E6EF;
  border-top: 5px solid #E4E6EF;
  border-bottom: 5px solid transparent;
  left: -11px;
  top: -1px;
}

.left-bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid #F3F6F9; // light
  border-top: 4px solid #F3F6F9;  // light
  border-bottom: 4px solid transparent;
  left: -8px;
  top: 0px;
  //top: 0px;
}








.right-empty-bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid $primary;
  border-right: 5px solid transparent;
  border-top: 5px solid $primary;
  border-bottom: 5px solid transparent;
  right: -11px;
  //top: 5px;
  top: -2px;
}





.right-empty-bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 4px solid #FFF;
  border-right: 4px solid transparent;
  border-top: 4px solid #FFF;
  border-bottom: 4px solid transparent;
  right: -6px;
  //top: 6px;
  top: 0px;
}

.task-bubble:before {
  border-left: 5px solid $warning;
  border-top: 5px solid $warning;
}

.task-bubble:after {
  border-left: 5px solid #FFF4DE;
  border-top: 5px solid #FFF4DE;
}

.note-bubble:before {
  border-left: 5px solid #febf45;
  border-top: 5px solid #febf45;
}

.note-bubble:after {
  border-left: 5px solid #F3F6F9;
  border-top: 5px solid #F3F6F9;
}



.left-empty-bubble:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 5px solid transparent;
  border-right: 5px solid #E4E6EF;
  border-top: 5px solid #E4E6EF;
  border-bottom: 5px solid transparent;
  left: -11px;
  top: -2px;
  //top: 0px;
}

.left-empty-bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 4px solid transparent;
  border-right: 4px solid #FFF; // white
  border-top: 4px solid #FFF;  // white
  border-bottom: 4px solid transparent;
  left: -6px;
  top: 0px;
  //top: 0px;
}

.sb11:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #FFA800;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -20px;
  top: 6px;
}

.sb11:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid #F3F6F9;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  left: -19px;
  top: 6px;
}


/* speech bubble 12 */

.sb12:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #00bfb6;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -23px;
}

.sb12:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid #fff;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -17px;
}

.sticky-note-item {
  background: $warning-light;
  display:block;
  -moz-box-shadow:5px 5px 7px rgba(33,33,33,1);
  -webkit-box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  box-shadow: 5px 5px 7px rgba(33,33,33,.7);
  -moz-transition:-moz-transform .15s linear;
  -o-transition:-o-transform .15s linear;
  -webkit-transition:-webkit-transform .15s linear;
  //-webkit-transform: rotate(-6deg);
  //-o-transform: rotate(-6deg);
  //-moz-transform:rotate(-6deg);
}




.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }

  50%{
    transform: scale(1.2);
  }

  100%{
    transform: scale(0.8);
  }
}

.chart-summary-value{
  height: 40px;
  width: auto;
  padding: 5px;
  text-align: center;
  font-size: 12px;
  border-radius: 0px;
  font-weight: bold;
  white-space: nowrap;
  min-width: 40px;
}